import { API_ORIGIN } from './constants';
import { t, lang } from './i18n';

const SITE_ORIGIN = window.location.origin || 'https://ego.jobs';

const JOB_TYPE_FILTER = 'jobType';
const JOB_CATEGORY_FILTER = 'category';
const CONTRACT_TYPE_FILTER = 'contractType';

const JOB_FILTERS = [
  {
    label: 'Engineering Manager',
    value: ['engineering_manager', 'technical_directors_manager'],
    filterType: JOB_CATEGORY_FILTER,
  },
  {
    label: 'Backend Developer',
    value: ['software_developers_web_backend', 'Backend Development'],
    filterType: JOB_CATEGORY_FILTER,
  },
  {
    label: 'UX/UI',
    value: [
      'product_designer',
      'ux_specialist',
      'User Experience Design',
      'User Experience Strategy',
    ],
    filterType: JOB_CATEGORY_FILTER,
  },
  {
    label: 'AI',
    value: [
      'data_scientist',
      'Data Science',
      'Data Analysis',
      'Big Data Analysis',
    ],
    filterType: JOB_CATEGORY_FILTER,
  },
  {
    label: 'SMM and Marketing',
    value: [
      'smm',
      'Content Marketing Specialist',
      'Online Marketing',
      'Online Marketing and Copywriting',
      'Social Media Marketing',
      'Social Media Management',
      'Social Media',
    ],
    filterType: JOB_CATEGORY_FILTER,
  },
  {
    label: 'Frontend Developer',
    value: [
      'web_frontend',
      'Frontend Development',
      'Frontend Engineer',
      'React',
      'Vue.Js',
    ],
    filterType: JOB_CATEGORY_FILTER,
  },
  {
    label: 'Product',
    value: [
      'product_owner',
      'product_manager',
      'Product Development',
      'Product Management',
      'Product',
    ],
    filterType: JOB_CATEGORY_FILTER,
  },
  {
    label: 'HR',
    value: [
      'human_resources_manager',
      'human_resources_specialist',
      'Human Resources',
      'Recruiting',
      'Recruitment',
      'HR',
      'HR Management',
      'Talent Management',
      'Talent Acquisition',
    ],
    filterType: JOB_CATEGORY_FILTER,
  },
  {
    label: 'DBA',
    value: [
      'database_architect',
      'database_administrator',
      'clinical_data_manager',
      'Data Management',
    ],
    filterType: JOB_CATEGORY_FILTER,
  },
  {
    label: 'QA',
    value: ['software_quality_assurance_engineers_and_tester'],
    filterType: JOB_CATEGORY_FILTER,
  },
  {
    label: 'Full-stack',
    value: ['software_web', 'Full Stack Development'],
    filterType: JOB_CATEGORY_FILTER,
  },
  {
    label: 'Mobile',
    value: ['Mobile Development', 'software_mobile', 'Kotlin', 'Swift'],
    filterType: JOB_CATEGORY_FILTER,
  },
  // { label: 'remote', value: 'remote', filterType: JOB_TYPE_FILTER },
  // { label: 'onsite', value: 'onsite', filterType: JOB_TYPE_FILTER },
  // { label: 'full-time', value: 'full', filterType: CONTRACT_TYPE_FILTER },
  // { label: 'part-time', value: 'part', filterType: CONTRACT_TYPE_FILTER },
];

let activeJobCategory = JOB_FILTERS[1].label;
let activeJobType = null;
let activeContractType = null;

async function fetchJobPosts() {
  let searchQuery = '?limit=6';

  if (activeJobCategory) {
    const value = JOB_FILTERS.find(
      filter => filter.label === activeJobCategory,
    )?.value;
    if (value) {
      searchQuery += `&${JOB_CATEGORY_FILTER}=${value.join(',')}`;
    }
  }

  if (activeJobType) {
    searchQuery += `&${JOB_TYPE_FILTER}=${activeJobType}`;
  }

  if (activeContractType) {
    searchQuery += `&${CONTRACT_TYPE_FILTER}=${activeContractType}`;
  }

  let fetchResult = await fetch(`${API_ORIGIN}/featured-jobs${searchQuery}`);

  const jobPosts = await fetchResult.json();

  return jobPosts;
}

const handleJobFilterClick = async filter => {
  const { label, filterType } = filter;

  switch (filterType) {
    case JOB_CATEGORY_FILTER:
      activeJobCategory = label;
      break;
    case JOB_TYPE_FILTER:
      activeJobType = label;
      break;
    case CONTRACT_TYPE_FILTER:
      activeContractType = label;
      break;
  }

  Array.from(document.getElementsByClassName('category-tag')).forEach(
    categoryTag => {
      const tagLabel = categoryTag.dataset.label;
      const tagFilterType = categoryTag.dataset.filtertype;

      if (tagLabel === label) {
        categoryTag.classList.add('active');
      } else if (tagFilterType === filterType) {
        categoryTag.classList.remove('active');
      }
    },
  );

  await populateJobPostsList();
};

const appendJobFiltersElement = () => {
  const jobCategoriesElement = document.querySelector(
    '#job-posts > .categories > .categories-list',
  );

  JOB_FILTERS.forEach(filter => {
    const { label, filterType } = filter;
    const tagElement = document.createElement('button');
    tagElement.classList.add('category-tag');
    tagElement.textContent = label;
    tagElement.dataset.filtertype = filterType;
    tagElement.dataset.label = label;

    if (
      label === activeJobCategory ||
      label === activeJobType ||
      label === activeContractType
    ) {
      tagElement.classList.add('active');
    }

    tagElement.addEventListener(
      'click',
      handleJobFilterClick.bind(this, filter),
    );

    jobCategoriesElement.appendChild(tagElement);
  });
};

const appendPickARoleElement = () => {
  const pickARoleElement = document.createElement('a');
  pickARoleElement.href = '#job-posts';
  pickARoleElement.classList.add('pick-a-role');
  pickARoleElement.textContent = t('carousel.pickARole');
  pickARoleElement.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth',
    });
  });

  const carouselElement = document.getElementById('carousel');
  carouselElement.appendChild(pickARoleElement);
};

const createJobPostElement = jobPost => {
  const { _id, data, owner } = jobPost;
  const {
    name,
    photo,
    data: { company: ownerData },
  } = owner;

  const jobPostElement = document.createElement('div');
  jobPostElement.classList.add('job-post');
  const headerElement = document.createElement('div');
  headerElement.classList.add('header');

  const userAvatarElement = document.createElement('div');
  userAvatarElement.classList.add('user-avatar');
  const fallbackSrc = `https://boiling-inlet-60193.herokuapp.com/v1/flares/?name=${name}`;
  userAvatarElement.style.backgroundImage = `url("${photo}"), url("${fallbackSrc}")`;

  const titleElement = document.createElement('div');
  titleElement.classList.add('title');

  const nameElement = document.createElement('h1');
  nameElement.textContent = name;
  titleElement.appendChild(nameElement);

  const roleElement = document.createElement('h2');
  roleElement.textContent = ownerData.currentRole;
  titleElement.appendChild(roleElement);

  headerElement.appendChild(userAvatarElement);
  headerElement.appendChild(titleElement);

  jobPostElement.appendChild(headerElement);

  const iconTagsElement = document.createElement('div');
  iconTagsElement.classList.add('icon-tags');

  if (ownerData.industry) {
    const industryLabel = ownerData.industry.label || ownerData.industry;
    const industryTagElement = document.createElement('div');
    industryTagElement.classList.add('icon-tag');

    const industryIconElement = document.createElement('div');
    industryIconElement.classList.add('icon', 'job');
    industryTagElement.appendChild(industryIconElement);

    const industryTextElement = document.createElement('p');
    industryTextElement.textContent = industryLabel;
    industryTagElement.appendChild(industryTextElement);
    iconTagsElement.appendChild(industryTagElement);
  }

  if (ownerData.currentCity) {
    let jobCity = ownerData.currentCity;
    if (Array.isArray(ownerData.currentCity)) {
      jobCity = ownerData.currentCity[0];
    }
    let jobCityLabel = jobCity.label;

    if (jobCity === 'weAreRemote') {
      jobCityLabel = 'We are remote';
    }

    const locationTagElement = document.createElement('div');
    locationTagElement.classList.add('icon-tag');

    const locationIconElement = document.createElement('div');
    locationIconElement.classList.add('icon', 'location');
    locationTagElement.appendChild(locationIconElement);

    const locationTextElement = document.createElement('p');
    locationTextElement.textContent = jobCityLabel;
    locationTagElement.appendChild(locationTextElement);
    iconTagsElement.appendChild(locationTagElement);
  }

  jobPostElement.appendChild(iconTagsElement);

  const lookingForElement = document.createElement('p');
  lookingForElement.classList.add('looking-for');
  lookingForElement.textContent = t('jobPosts.lookingFor');
  jobPostElement.appendChild(lookingForElement);

  const jobTitleElement = document.createElement('h1');
  jobTitleElement.textContent = data.title;
  jobPostElement.appendChild(jobTitleElement);

  const jobDescriptionElement = document.createElement('div');
  jobDescriptionElement.classList.add('description');
  jobDescriptionElement.innerHTML =
    data.briefDescription;
  jobPostElement.appendChild(jobDescriptionElement);

  if (data.relevantSkills) {
    const jobTagsElement = document.createElement('div');
    jobTagsElement.classList.add('tags');
    data.relevantSkills.forEach(skill => {
      const tagElement = document.createElement('div');
      tagElement.classList.add('tag');
      tagElement.textContent = skill.label;
      jobTagsElement.appendChild(tagElement);
    });

    jobPostElement.appendChild(jobTagsElement);
  }
  const buttonElement = document.createElement('a');
  buttonElement.target = '_blank';
  buttonElement.href = `${SITE_ORIGIN}/${lang}/p/${name}/${_id}`;
  buttonElement.classList.add('button');
  buttonElement.textContent = t('jobPosts.buttonLabel');

  jobPostElement.appendChild(buttonElement);

  return jobPostElement;
};

const populateJobPostsList = async () => {
  const jobPosts = await fetchJobPosts();

  const jobPostsListElement = document.querySelector(
    '#job-posts > .job-posts-list',
  );

  jobPostsListElement.innerHTML = '';

  if (!jobPosts?.length) {
    return;
  }

  jobPosts.forEach(jobPost => {
    const jobPostElement = createJobPostElement(jobPost);
    jobPostsListElement.appendChild(jobPostElement);
  });

  // const spacerElement = document.createElement('div');
  // spacerElement.classList.add('job-post', 'spacer');
  // jobPostsListElement.appendChild(spacerElement);
};

export async function initJobPosts() {
  try {
    await populateJobPostsList();
    appendPickARoleElement();
    appendJobFiltersElement();

    const jobPostsElement = document.getElementById('job-posts');
    jobPostsElement.classList.add('initialized');
    const featuresElement = document.getElementById('features');
    featuresElement.classList.add('rounded');
  } catch (e) {
    return;
  }
}
