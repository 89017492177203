const THEME_KEY = 'ego.jobs.theme';

export function initTheme() {
  const theme = localStorage.getItem(THEME_KEY);

  if (theme === 'LIGHT') {
    document.body.classList.add('light');
  } else if (theme !== 'DARK') {
    const lightThemeMq = window.matchMedia('(prefers-color-scheme: light)');
    if (lightThemeMq.matches) {
      localStorage.setItem(THEME_KEY, 'LIGHT');
      document.body.classList.add('light');
    }
  }
}
